<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      :style="styledBg"
      class="
        component
        position-relative
        c-signees
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div>
        <div class="row padding-x-12 position-relative z-index-3">
          <common-header
            :headline="section.headline"
            :sponsor="section.sponsor"
          />
        </div>
        <div class="row padding-x-12 position-relative z-index-3">
          <div
            class="
              flex-item-1
              padding-t-24
              width-100
              s-common__interactions
              c-signees__interactions
              display-flex
              justify-content-flex-end
            "
          >
            <div class="s-common__dropdown width-100 display-none">
              <select
                name="LeaveType"
                @change="filter_players($event.target.value)"
              >
                <option value="list">all</option>
                <option value="offense">offense</option>
                <option value="defense">defense</option>
                <option value="special teams">special teams</option>
              </select>
            </div>
            <div class="s-common__filter c-signees__filters display-none">
              <button
                class="
                  s-common__filter-button
                  c-signees__filter-button
                  font-size-14
                  line-height-125
                "
                :class="{ 'is-active': active_filter === 'all' }"
                @click="filter_players('all')"
              >
                All
              </button>
              <button
                class="
                  s-common__filter-button
                  c-signees__filter-button
                  font-size-14
                  line-height-125
                "
                :class="{
                  'is-active': active_filter === 'offense',
                }"
                @click="filter_players('offense')"
              >
                Offense
              </button>
              <button
                class="
                  s-common__filter-button
                  c-signees__filter-button
                  font-size-14
                  line-height-125
                "
                :class="{
                  'is-active': active_filter === 'defense',
                }"
                @click="filter_players('defense')"
              >
                Defense
              </button>
              <button
                class="
                  s-common__filter-button
                  c-signees__filter-button
                  font-size-14
                  line-height-125
                "
                :class="{
                  'is-active': active_filter === 'special teams',
                }"
                @click="filter_players('special teams')"
              >
                Special Teams
              </button>
            </div>
            <div
              class="
                s-common__tabs
                c-signees__tabs
                align-self-center
                width-100
                bp-768:width-auto
                justify-content-center
                bp-768:justify-content-start
              "
            >
              <button
                class="
                  s-common__tab
                  c-signees__tab
                  font-size-14
                  line-height-150
                "
                :class="{
                  'is-active': active_view === 'list',
                }"
                @click="change_view('list')"
              >
                <div class="s-common__tab--dot c-signees__tab--dot"></div>
                <span>Table View</span>
              </button>
              <button
                class="
                  s-common__tab
                  c-signees__tab
                  font-size-14
                  line-height-150
                "
                :class="{
                  'is-active': active_view === 'cards',
                }"
                @click="change_view('cards')"
              >
                <div class="s-common__tab--dot c-signees__tab--dot"></div>
                <span>Card View</span>
              </button>
            </div>
          </div>
        </div>

        <div class="c-signees__content position-relative z-index-3">
          <div
            v-show="active_view === 'cards'"
            class="c-signees__view--cards row padding-6"
          >
            <transition-group
              v-if="filtered_players.length"
              class="
                c-signees__cards
                reset-list
                flex-item-1 flex-wrap-wrap
                display-flex
                width-100
              "
              name="fade"
              tag="ul"
            >
              <template v-if="template.name === 'roster-1'">
                <li
                  v-for="player in filtered_players"
                  :key="player.player_id"
                  class="
                    c-signees__cards--item
                    width-100
                    bp-768:width-50
                    bp-1024:width-33
                    bp-1280:width-25
                    padding-6
                  "
                >
                  <SectionRosterCard
                    :template="template"
                    :player="player"
                  ></SectionRosterCard>
                </li>
              </template>
              <template v-if="template.name === 'roster-2'">
                <li
                  v-for="player in filtered_players"
                  :key="player.player_id"
                  class="
                    c-signees__cards--item
                    width-100
                    bp-540:width-50
                    bp-768:width-33
                    bp-1024:width-25
                    bp-1280:width-20
                    padding-6
                  "
                >
                  <SectionRosterCard
                    :template="template"
                    :player="player"
                  ></SectionRosterCard>
                </li>
              </template>
            </transition-group>
            <div
              class="padding-y-12 padding-x-6"
              v-if="!filtered_players.length"
            >
              Please choose another filter.
            </div>
          </div>
          <div
            class="c-signees__view--list row padding-x-12"
            v-show="active_view === 'list'"
          >
            <div class="c-signees__table-wrapper">
              <table v-if="filtered_players.length" class="c-signees__table">
                <thead>
                  <th
                    class="font-size-16 line-height-150 font-weight-700"
                    role="button"
                    v-for="(column, index) in columns"
                    :key="index"
                    v-on:click="sortBy(column.shortcode, column)"
                  >
                    <span>{{ column.label }}</span>
                    <span
                      v-bind:class="{
                        active: column.active,
                        reverse: reverse,
                      }"
                    ></span>
                  </th>
                </thead>
                <tbody>
                  <SectionRosterRow
                    v-for="player in filtered_players"
                    :key="player.player_id"
                    :player="player"
                  ></SectionRosterRow>
                </tbody>
              </table>
              <div
                class="padding-y-12 padding-x-6"
                v-if="!filtered_players.length"
              >
                Please choose another filter.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { sort_by } from "@/helpers";
import SectionRosterCard from "@/components/sections/SectionRosterCard.vue";
import SectionRosterRow from "@/components/sections/SectionRosterRow.vue";
import SectionBase from "@/components/sections/SectionBase.js";
import CommonHeader from "@/components/common/CommonHeader.vue";
export default {
  name: "SectionRosters",
  extends: SectionBase,
  components: { SectionRosterCard, SectionRosterRow, CommonHeader },
  data: () => ({
    offensive_positions: ["qb", "ol", "g", "rb", "te", "wr", "ath"],
    defensive_positions: ["lb", "cb", "de", "dt", "lb", "s", "db", "ath"],
    special_teams_positions: ["k", "ath"],
    year: new Date().getFullYear(),
    active_view: "cards",
    active_filter: "all",
    active_sort: "default",
    reverse: false,
    sortKey: "lastname",
    columns: [
      { label: "Name", shortcode: "lastname", active: true },
      { label: "Position", shortcode: "position_short", active: false },
      { label: "Year", shortcode: "rp_academic_year", active: false },
      { label: "Hometown", shortcode: "player_hometown", active: false },
      { label: "More Info.", shortcode: "", active: false },
    ],
  }),
  methods: {
    change_view(view) {
      this.active_view = view;
    },
    filter_players(filter) {
      this.active_filter = filter;
    },
    sortBy: function (sortKey, item) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
      this.columns.forEach((item) => (item.active = false));
      item.active = true;
    },
  },
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
    show_section() {
      return (
        this.section.navMenuText ||
        this.section.selectedRosterId ||
        (this.section.rosterPlayers && this.section.rosterPlayers.length) ||
        (this.section.sponsor && this.section.sponsor.image) ||
        (this.section.headline && this.section.headline != "Signee Roster")
      );
    },
    offensive_players() {
      return this.$root.roster.filter((player) => {
        if (
          this.offensive_positions.includes(player.position_short.toLowerCase())
        ) {
          return player;
        }
      });
    },
    defensive_players() {
      return this.$root.roster.filter((player) => {
        if (
          this.defensive_positions.includes(player.position_short.toLowerCase())
        ) {
          return player;
        }
      });
    },
    special_teams_players() {
      return this.$root.roster.filter((player) => {
        if (
          this.special_teams_positions.includes(
            player.position_short.toLowerCase()
          )
        ) {
          return player;
        }
      });
    },
    filtered_players() {
      var _fp = this.$root.roster.slice();
      if (!this.$root.roster.length) {
        return [];
      }
      if (this.active_filter === "all") {
        _fp = this.$root.roster.slice();
      } else if (this.active_filter === "offense") {
        _fp = this.offensive_players;
      } else if (this.active_filter === "defense") {
        _fp = this.defensive_players;
      } else if (this.active_filter === "special teams") {
        _fp = this.special_teams_players;
      }
      // return _fp;
      if (this.reverse) {
        return _fp.sort(sort_by(this.sortKey.toString())).reverse();
      } else {
        return _fp.sort(sort_by(this.sortKey.toString()));
      }
    },
  },
};
</script>

<style scoped lang="scss">
// Transitions
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.fade-leave-active {
  position: absolute;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.35s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
}
.list-move {
  transition: all 0.35s;
}

.c-signees {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
  &__cards {
  }
  &__view {
    &--cards {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 12px;
    &-wrapper {
      overflow: auto;
      width: 100%;
    }
    thead {
      background: $main-gray-color;
      th {
        height: 72px;
        padding: 0 12px;
        color: $main-font-color;
        border-spacing: 0;
        border: none;
        box-shadow: inset 0px -1px 0px #f1f1f1;
        text-align: center;
        &:first-child {
          text-align: left;
          padding: 0 24px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      thead {
        display: none;
      }
    }
  }
}
</style>
